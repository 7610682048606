@font-face {
  font-family: 'tt-common';
  src: url(../public/assets/fonts/TT\ Commons\ Medium.otf);
}

/* @font-face {
  src: url(../public/assets/fonts/akzidenzgroteskpro_md.ttf);
}  */

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'tt-common', sans-serif;

}

h2 {
  font-weight: 500;
  font-size: 60px;
  color: #1C1B1B;
}

.bg-light1 {
  background: #F5F5F5;
}


.text-gradient {
  background: linear-gradient(180deg, rgba(238, 124, 252, 0.00) 10.29%, #EE7CFC 100.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient1 {
  background: linear-gradient(180deg, #EE7CFC 0%, rgba(238, 124, 252, 0.00) 100.77%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-yellow {
  color: #FCC076 !important;
}

.fs-14 {
  font-size: 14px !important;
}

/* Header  */

.navbar {
  height: 75px;
  position: fixed;
  width: 100%;
  z-index: 111;
  background: rgba(255, 255, 255, 0.49);
  backdrop-filter: blur(7px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #000;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #EB6AFF;
  transition: all .2s ease-in-out;
}

nav a.launch {
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #000;
  position: relative;
  text-align: center;
}

nav a.launch:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #61DFFF;
  height: 2px;
  transition: all .5s;
}

nav a.launch:hover:after {
  width: 100%;
}


.navbar-collapse.collapsing {
  transition: none !important;
}

.h-40 {
  height: 40px !important;
}

/* Banner */

.banner {
  padding-top: 150px;
  padding-bottom: 120px;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg-1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner h1 {
  font-weight: 500;
  font-size: 84px;
  text-transform: capitalize;
  color: #1C1B1B;
}

.banner p {
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #1C1B1B;
}



.primary-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #fff;
  background: #000;
  border-radius: 10px;
  height: 54px;
  padding: 14px 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: #EB6AFF;
  transition: all .3s ease-in-out;
  color: white;
}

.primary-btn-outline {
  border-radius: 50px;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  line-height: 32px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  color: #000;
  height: 54px;
  max-height: 64px;
  padding: 14px 32px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: #EB6AFF;
  color: #fff;
  transition: all .1s ease-in-out;
}


.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}






@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }


}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }


  .banner {
    position: relative;
    z-index: 111;
    align-items: flex-start;
    height: auto;
    padding-top: 120px;
    background-size: cover;
    background-position: center;
    padding-bottom: 50px;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 54px;
    padding-top: 0px;
  }

  .banner p {
    font-size: 18px;
    line-height: 29px;
  }

  .banner::before {
    content: none;
  }

  .banner p {
    margin-bottom: 35px;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .nav-link {
    font-weight: 600 !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
    height: calc(100% - 250px);
  }

  .navbar-brand img {
    height: 30px;
  }



}



.vision-card {
  border-radius: 10px;
  background: #FFF;
  padding: 50px 25px 25px;
  height: 100%;
}


.vision-card h4 {
  color: #000;
  font-family: 'Inter', sans-serif !important;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}

.vision-card ul {
  padding-left: 25px;
}

.vision-card ul li {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  list-style: disc;
}

.use-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.use-card h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.bg-1 {
  background: linear-gradient(91deg, #849EFF 0.94%, #EA7DFC 100%);
}

.bg-2 {
  background: #252A33;
}

.bg-3 {
  background: linear-gradient(94deg, #EE7CFC 23.45%, #FDBC7D 84.02%);
}

.bg-4 {
  background: #FDBC7D;
}

/* faqs css  */




.faq .accordion-item:first-of-type .accordion-button {
  background-color: transparent !important;
  border: 0;
  padding-right: 80px;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/-.svg);
  /* transform: rotate(-180deg); */
}

.faq .accordion-button:not(.collapsed)::before {
  background: transparent;
}

.faq .accordion-button::before {
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  position: absolute;
  content: "";
  right: 3px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  z-index: 0;
}

.faq .accordion-button::before :hover {
  background: transparent;
}

.faq .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../public/assets/+.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
  z-index: 9;
  position: absolute;
  right: 20px;
}

.faq .accordion-button::after:hover {
  background-color: red;
}

.faq .accordion-item {
  background-color: transparent;
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid #BDC0C4;
  padding: 5px 0px;
  margin-bottom: 15px;
}

.faq .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq .accordion-button {
  border-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  color: #000;
  font-size: 28px;
  font-weight: 500;
  padding: 15px 60px 15px 0px !important;
}


.faq .accordion-body {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  padding-left: 0;
  color: #000;
}

.faq .accordion-body a {
  color: #06F4FF;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

/* footer  */
footer {
  background: black;
}

footer ul li a {
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  margin-bottom: 20px;
  line-height: 17px;
  color: #FFFFFF;
  position: relative;
  transition: all .2s ease-in-out;
}

footer ul li a:hover {
  color: #EB6AFF;
  transition: all .2s ease-in-out;


}

@media (max-width: 767px) {

  .primary-btn-outline,
  .primary-btn {
    font-size: 16px;
    height: 54px;
  }

  h2 {
    font-size: 34px !important;
  }

  .vision-card ul li {
    font-size: 16px;
  }

  footer {
    padding: 0px 25px;
  }

  .footer-logo {
    margin-bottom: 20px;
    text-align: center;
  }

  .faq .accordion-button {
    font-size: 22px;
    line-height: 30px;
    padding: 8px 40px 8px 0px !important;
  }

  .faq .accordion-body {
    font-size: 14px;
    line-height: 22px;
  }

  .faq .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 0.9rem;
    right: 15px;
  }


  .faq .accordion-item {
    padding: 5px 0px 5px 0px;
  }

}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1080px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}